@import "constants";
@mixin tablet-and-up {
    @media screen and (min-width: 769px) {
        @content;
    }
}
@mixin mobile-and-up {
    @media screen and (min-width: 601px) {
        @content;
    }
}
@mixin tablet-and-down {
    @media screen and (max-width: 768px) {
        @content;
    }
}
@mixin mobile-only {
    @media screen and (max-width: 600px) {
        @content;
    }
}

.home-header {
    background-image: linear-gradient(to top, rgba(255, 255, 255, 0.5), rgba(255, 184, 0, 0.5)),
        url("/assets/img//background-image.png");
    background-repeat: no-repeat;
    background-size: cover;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 11;
    .header-title {
        font-size: 30px;
        padding-top: 50px;
        background: -webkit-linear-gradient(#7774fd, #3d64fd, #1d5cfd);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        max-width: $header-max-width;
        margin: auto;
        margin-bottom: 15px;
    }
    .header-tabs {
        display: flex;
        max-width: $header-max-width;
        width: 100%;
        margin: auto;

        .header-tab-item {
            text-align: center;
            flex: 1;
            padding: 10px;
            i {
                font-size: 35px;
                height: 40px;
                display: block;
                margin-bottom: 5px;
                &.icomoon-meta-3d,
                &.icomoon-shopping {
                    height: 40px;
                    font-size: 30px;
                    padding: 5px;
                }
                &.icomoon-route {
                    font-size: 40px;
                }
            }
            .tab-item-title {
                font-size: 13px;
                margin-bottom: 0;
            }
            &.active {
                color: #4db7fe;
            }
        }
    }
    &.level1 {
        .header-title {
            padding-top: 10px;
        }
        .top-content-header:nth-child(2),
        .header-search-bar {
            display: none;
        }
    }
    &.level2 {
        .header-title {
            padding-top: 10px;
        }
        .top-content-header {
            display: none;
        }
        .header-search-bar {
            display: none;
        }
    }
    &.level3 {
        .header-title {
            padding-top: 10px;
        }
        .ballon {
            display: none;
        }
    }
    &.level4 {
        .header-title {
            padding-top: 10px;
        }
        .ballon {
            display: none;
        }
        .header-tabs .header-tab-item i {
            display: none;
        }
    }

    .header-content {
        max-width: $page-max-width;
        margin: auto;
    }
    .top-content-header {
        display: flex;
        padding: 8px 10px;
        align-items: center;
        border-bottom: 1px solid #949aa9;
        line-height: 30px;
        transition: 1s;
        .top-header-title {
            flex: 1;
        }
        .btn-header-selector {
            background-color: #2d3f6e;
            color: #ffffff;
            padding: 5px 18px;
            border-radius: 50px;
        }
    }
    .header-search-bar {
        display: flex;
        padding: 8px 10px;
        align-items: center;
        border-bottom: 1px solid #949aa9;
        font-size: 16px;
        gap: 8px;
        transition: 1s;
        .search-bar-icon {
            font-size: 20px;
            font-weight: bold;
            color: #000;
        }
        .search-bar-text {
            flex: 1;

            line-height: 30px;
        }
    }
    .header-btn-actions {
        padding: 15px 0;
        .header-btn-search {
            padding: 15px 16px;
            font-weight: 14px;
            width: 100%;
            text-decoration: none;
            display: block;
            text-align: center;
            border-radius: 50px;
            i {
                margin-right: 10px;
            }
            &.place-btn {
                background: linear-gradient(to right, #e8a633, #f2cf34, #e8a633);
                color: #000000;
            }
            &.activity-btn {
                background: linear-gradient(to right, #013cd3, #4671de, #013cd3);
                color: #fff;
            }
            &.transit-btn {
                background: linear-gradient(to right, #379189, #43c9bc, #379189);
                color: #fff;
            }
            &.shopping-btn {
                background: linear-gradient(to right, #379189, #0c655d, #379189);
                color: #fff;
            }
        }
    }
    .ballon {
        margin-bottom: 50px;
        margin-top: 15px;
        max-width: $header-max-width;
        transition: 1s;
    }
    .vr-content {
        margin-bottom: -28px;
        position: absolute;
        bottom: 0;
        max-width: 600px;
        width: calc(100% - 20px);

        @media (min-width: 600px) {
            left: 50%;
            margin-left: -300px;
        }
        .vr-content-title {
            background-color: #1d5cfd;
            padding: 16px 20px;
            text-align: center;
            color: #fff;
            width: 100%;
            max-width: 500px;
            margin: auto;
            border-radius: 50px;
        }
    }
    .province-content {
        padding-top: 2$mobile-default-header-height;
        .province-switch-box {
            text-align: right;
            max-width: 500px;
            margin: auto;
            .btn-area-selector {
                border-radius: 50px;
                background-color: #000000;
                color: #ffffff;
                padding: 8px 20px;
            }
        }
    }
}
.home-content {
    .transit-banner {
        width: 100%;
    }
    .app-area-list {
        padding: 8px 0;
        overflow: hidden;
        .app-area-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            p.area-title {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 150px;
                height: 200px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
        &.scroll-container {
            display: flex;
            overflow: auto;
            gap: 8px;
            .app-area-item {
                width: 150px;
                flex: 0 0 150px;
                display: inline-block;
            }
        }
    }
    .highlight-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        padding: 8px 0;
        .highlight-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .highlight-zone {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
            }
            &.shimmer-box {
                width: 100%;
                height: calc((100vw - 56px) * 0.25);
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
                max-height: 217.5px;
                &:first-child {
                    height: calc((100vw - 56px) * 0.5);
                    max-height: 435px;
                }
            }
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
            }
        }
    }
    .destination-map-box {
        display: flex;
        padding: 8px 16px;
        border: 1px solid #c3c1c1;
        border-radius: 50px;
        gap: 8px;
        align-items: center;
        max-width: 500px;
        width: 90%;
        margin: 8px auto;

        .map-box-icon {
            width: 30px;
            img {
                width: 30px;
            }
        }
        .map-box-text {
            flex: 1;
            text-align: center;
            font-size: 12px;
        }
        .map-box-link {
            background-color: #b85af9;
            padding: 5px 10px;
            border-radius: 50px;
            color: #ffffff;
            font-size: 12px;
            svg {
                vertical-align: baseline;
                height: 12px;
                display: inline-block;
                margin-bottom: -1px;
            }
        }
    }
    .explore-list {
        padding: 8px 0;
        overflow: hidden;
        .explore-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .explore-item-info {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                line-height: 1.3;
                p.zone-title {
                    font-size: 14px;
                    margin: 0;
                    i {
                        font-size: 12px;
                        margin-right: 5px;
                    }
                    margin-bottom: 5px;
                }
                p.zone-info {
                    margin: 0;
                    font-weight: bold;
                    b {
                        color: #ffcc03;
                    }
                }
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 170px;
                height: 230px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
        &.scroll-container {
            display: flex;
            gap: 8px;
            overflow: auto;

            .explore-item {
                width: 170px;
                flex: 0 0 170px;
                display: inline-block;
            }
        }
    }
    .activity-box {
        .activity-tabs {
            display: flex;
            .tab-item {
                flex: 1;
                text-align: center;
                padding: 8px;
                img.tab-item-img {
                    max-width: 100%;
                    margin-bottom: 5px;
                }
                p {
                    margin: 0;
                    font-size: 8px;
                }
            }
        }
        .acitity-list {
            padding: 8px 0;
            .activity-item-box {
                box-shadow: 0 3px 6px #00000029;
                border: 0.2px solid #cbcbcb;
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                .activity-img {
                    width: 100%;
                    img {
                        width: 100%;
                    }
                }
                .activity-info {
                    display: block;
                    position: relative;
                    .activity-sale-tag {
                        display: inline-block;
                        position: absolute;
                        top: -20px;
                        background-color: #f32d18;
                        border-radius: 2px;
                        padding: 3px 8px;
                        color: #ffffff;
                        font-size: 10px;
                    }
                }
            }
        }
    }
    .do-tags-box {
        .do-tags-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
            padding: 8px 0;
            .tag-item {
                border-radius: 8px;
                position: relative;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                    border-radius: 8px;
                }
                .tag-name {
                    display: block;
                    background: linear-gradient(to top, #000000b3, #00000000);
                    position: absolute;
                    bottom: 0;
                    margin: 0;
                    padding: 8px 10px;
                    color: #ffffff;
                    width: 100%;
                }
                &.shimmer-box {
                    width: 100%;
                    height: calc((100vw - 56px) * 0.33);
                    margin: 0 5px;
                    display: inline-block;
                    border-radius: 8px;
                    max-height: 217.5px;
                    &:first-child {
                        height: calc((100vw - 56px) * 0.66);
                        max-height: 435px;
                    }
                }
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
                &:nth-child(9) {
                    grid-column-start: 2;
                    grid-column-end: 4;
                    grid-row-start: 4;
                    grid-row-end: 6;
                }
            }
        }
    }
    .journey-list {
        padding: 8px 0;
        overflow: hidden;
        .journey-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            border: 0.5px solid #adadad;
            img {
                width: 100%;
                object-fit: cover;
            }
            .journey-item-info {
                padding: 8px 10px;
                p.joury-title {
                    display: block;
                    margin: 0;
                    color: #b100ff;
                    width: 100%;
                    white-space: normal;
                    font-size: 12px;
                    line-height: 1.7;
                }
                p.joury-info {
                    display: block;
                    bottom: 0;
                    margin: 0;
                    width: 100%;
                    color: #505050;
                    font-size: 12px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 340px;
                height: 233px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
        &.scroll-container {
            display: flex;
            gap: 8px;
            overflow: auto;

            .journey-item {
                width: 340px;
                flex: 0 0 340px;
                display: inline-block;
            }
        }
    }
    .transit-tabs {
        display: flex;
        gap: 8px;
        margin: 8px 0;

        .tab-item {
            flex: 1;
            max-width: 25%;
            .tab-img {
                width: 50px;
                margin: auto;
                display: block;
                margin-bottom: 5px;
            }
            .tab-name {
                font-size: 11px;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
    .travel-list {
        .travel-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .travel-item-info {
                display: block;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.7019607843), rgba(0, 0, 0, 0));
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                line-height: 1.3;
                font-size: 14px;
                svg {
                    margin: 0 5px;
                    font-size: 12px;
                }
                .travel-to {
                    color: #ffcc03;
                }
            }
        }
    }
    .travel-zone-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
        padding: 8px 0;
        .travel-zone-item {
            max-width: 100%;
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            .travel-zone-item-info {
                display: flex;
                background: linear-gradient(to top, rgba(0, 0, 0, 0.7019607843), rgba(0, 0, 0, 0));
                position: absolute;
                bottom: 0;
                left: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                line-height: 1.3;
                font-size: 14px;
                .travel-zone-left {
                    flex: 1;
                    svg {
                        margin: 0 5px;
                        font-size: 12px;
                    }
                }
            }
        }
        .shimmer-box {
            width: 100%;
            height: calc((100vw - 40px) / 2);
            display: block;
            border-radius: 8px;
        }
    }
    .transit-explore-list {
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 8px;
        .explore-tab {
            background: linear-gradient(to bottom, #e7fe8b3b, #15edf43b);
            text-align: center;
            padding: 20px 10px;
            border-radius: 8px;
            img {
                max-height: 70px;
                margin-bottom: 5px;
            }
            p {
                margin: 0;
                font-size: 10px;
                &.explore-title {
                    font-size: 14px;
                    font-weight: bold;
                }
            }
        }
    }
    .vr-header-empty {
        height: 30px;
        width: 100%;
    }
    .vr-title {
        font-weight: bold;
        text-align: center;
        font-size: 10px;
        margin-bottom: 15px;
    }
    .vr-description {
        font-weight: bold;
        text-align: left;
        font-size: 10px;
        margin-bottom: 10px;
    }
    .vr-place-list {
        display: grid;
        grid-template-columns: 1fr;
        gap: 8px;
        @media (min-width: 600px) {
            grid-template-columns: 1fr 1fr;
        }
        .vr-place-item {
            position: relative;
            border-radius: 8px;
            overflow: hidden;
            padding-top: 55%;
            img {
                position: absolute;
                max-width: 100%;
                border-radius: 8px;
                object-fit: cover;
                top: 0;
                bottom: 0;
                right: 0;
                left: 0;
                height: 100%;
                width: 100%;
            }
            .vr-place-name {
                background: linear-gradient(to top, rgba(0, 0, 0, 0.7019607843), rgba(0, 0, 0, 0));
                position: absolute;
                bottom: 0;
                left: 0;
                right: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                line-height: 1.3;
                font-size: 14px;
                margin: 0;
            }
        }
    }
}
.provicne-content {
    padding-top: 500px;
    @media (max-width: 700px) {
        & {
            padding-top: 496px;
        }
    }
    .weather-box {
        display: flex;
        align-items: center;
        padding: 8px 20px;
        gap: 8px;
        p {
            margin: 0;
            color: #ffffff;
        }

        .weather-temperature,
        .weather-chance-rain,
        .weather-wind {
            flex: 1;
            text-align: center;
            font-size: 12px;
        }
        .weather-temperature {
            display: flex;
            align-items: center;
            gap: 5px;
            justify-content: center;
            .weather-icon {
                flex: 0 0 auto;
                text-align: center;
                max-width: 100%;
                img {
                    height: 26px;
                    margin-bottom: 5px;
                }
                .weather-text {
                    font-size: 10px;
                    width: 60px;
                    color: #ffffff;
                }
            }
            .temperature-text {
                flex: 0 0 auto;
            }
        }
        &.loading {
            .shimmer-cicle {
                width: 26px;
                height: 26px;
                border-radius: 50%;
            }
        }
    }
    .menu-box {
        display: grid;
        align-items: stretch;
        grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        padding: 8px 20px;
        gap: 8px;

        .menu-item {
            flex: 1;
            text-align: center;
            img {
                width: 48px;
                height: 48px;
                margin-bottom: 5px;
            }
            .menu-title {
                font-size: 10px;
                margin-bottom: 0;
            }
        }
        @media (max-width: 700px) {
            & {
                grid-template-columns: 1fr 1fr 1fr 1fr;
            }
        }
        &.loading {
            .shimmer-cicle {
                width: 48px;
                height: 48px;
                margin: auto;
                margin-bottom: 5px;

                border-radius: 50%;
            }
        }
    }
    .map-link {
        display: flex;
        padding: 8px 16px;
        border: 1px solid #c3c1c1;
        border-radius: 50px;
        gap: 8px;
        align-items: center;
        max-width: 500px;
        width: 90%;
        margin: 8px auto;

        .link-img {
            width: 25px;
            img {
                width: 25px;
            }
        }
        .link-text {
            flex: 1;
            text-align: center;
            font-size: 12px;
        }
        .link-go {
            background-color: #b85af9;
            padding: 3px 10px;
            border-radius: 50px;
            color: #ffffff;
            font-size: 11px;
            svg {
                vertical-align: baseline;
                height: 11px;
                display: inline-block;
                margin-bottom: -1px;
            }
        }
    }
    .transit-tabs {
        display: flex;
        gap: 8px;
        margin: 8px 0;
        .tab-item {
            flex: 1;
            max-width: 25%;
            .tab-img {
                width: 50px;
                margin: auto;
                display: block;
                margin-bottom: 5px;
            }
            .tab-name {
                font-size: 11px;
                text-align: center;
                margin-bottom: 0;
            }
        }
    }
    .app-area-list {
        padding: 8px 0;
        overflow: hidden;
        .app-area-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            p.area-title {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 208px;
                height: 273px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
        &.scroll-container {
            display: flex;
            gap: 8px;
            overflow: auto;

            .app-area-item {
                width: 150px;
                flex: 0 0 150px;
                display: inline-block;
            }
        }
    }
    .highlight-list {
        padding: 8px 0;
        overflow: hidden;
        .highlight-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            img {
                width: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
            p.highlight-title {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                overflow: hidden;
                text-overflow: ellipsis;
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 208px;
                height: 273px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
    }
    .zone-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 5px;
        padding: 8px 0;
        .zone-item {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                object-fit: cover;
                height: 100%;
            }
            .zone-name {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                font-size: 13px;
                width: 100%;
            }
            &.shimmer-box {
                width: 100%;
                height: calc((100vw - 56px) * 0.25);
                margin: 0 5px;
                display: inline-block;
                max-height: 217.5px;
                &:first-child {
                    height: calc((100vw - 56px) * 0.5);
                    max-height: 435px;
                }
            }
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
            }
        }
    }
    .food-list {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 8px;
        padding: 8px 0;
        .food-item {
            position: relative;
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
            .food-name {
                display: block;
                background: linear-gradient(to top, #000000b3, #00000000);
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                font-size: 13px;
                width: 100%;
            }
            &.shimmer-box {
                width: 100%;
                height: calc((100vw - 56px) * 0.25);
                margin: 0 5px;
                display: inline-block;
                max-height: 217.5px;
                &:first-child {
                    height: calc((100vw - 56px) * 0.5);
                    max-height: 435px;
                }
            }
            &:first-child {
                grid-column-start: 1;
                grid-column-end: 3;
                grid-row-start: 1;
                grid-row-end: 3;
            }
            &:nth-child(6) {
                grid-column-start: 2;
                grid-column-end: 4;
                grid-row-start: 3;
                grid-row-end: 5;
            }
        }
    }
    .do-tags-box {
        .do-tags-list {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr;
            gap: 8px;
            padding: 8px 0;
            .tag-item {
                position: relative;
                overflow: hidden;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover;
                }
                .tag-name {
                    display: block;
                    background: linear-gradient(to top, #000000b3, #00000000);
                    position: absolute;
                    bottom: 0;
                    margin: 0;
                    padding: 8px 10px;
                    color: #ffffff;
                    width: 100%;
                    font-size: 13px;
                }
                &.shimmer-box {
                    width: 100%;
                    height: calc((100vw - 56px) * 0.33);
                    margin: 0 5px;
                    display: inline-block;
                    max-height: 217.5px;
                    &:first-child {
                        height: calc((100vw - 56px) * 0.66);
                        max-height: 435px;
                    }
                }
                &:first-child {
                    grid-column-start: 1;
                    grid-column-end: 3;
                    grid-row-start: 1;
                    grid-row-end: 3;
                }
                &:nth-child(9) {
                    grid-column-start: 2;
                    grid-column-end: 4;
                    grid-row-start: 4;
                    grid-row-end: 6;
                }
            }
        }
    }
    .blogs-list {
        .blog-item {
            display: flex;
            align-items: center;
            margin-bottom: 8px;
            gap: 8px;
            img {
                width: 120px;
                max-width: 100%;
                border-radius: 8px;
                @media (min-width: 700px) {
                    & {
                        width: 200px;
                    }
                }
            }

            .blog-content {
                flex: 1;
                padding: 8px;
                .blog-name {
                    font-size: 15px;
                    color: #000;
                    font-weight: 500;
                    margin-bottom: 5px;
                }
                .blog-description {
                    font-size: 12px;
                }
            }
            .shimmer {
                width: 120px;
                height: 90px;
                border-radius: 8px;
            }
            &:first-child {
                display: block;
                img {
                    width: 100%;
                }
                .blog-content {
                    padding: 8px 0;
                }
                .shimmer {
                    width: 100%;
                    height: 200px;
                    border-radius: 8px;
                }
            }
        }
        p {
            margin: 0;
        }
    }
    .journey-list {
        padding: 8px 0;
        overflow: hidden;
        .journey-item {
            border-radius: 8px;
            position: relative;
            overflow: hidden;
            border: 0.5px solid #adadad;
            img {
                width: 100%;
                object-fit: cover;
            }
            .journey-item-info {
                padding: 8px 10px;
                p.joury-title {
                    display: block;
                    margin: 0;
                    color: #b100ff;
                    width: 100%;
                    white-space: normal;
                    font-size: 12px;
                    line-height: 1.7;
                }
                p.joury-info {
                    display: block;
                    bottom: 0;
                    margin: 0;
                    width: 100%;
                    color: #505050;
                    font-size: 12px;
                    overflow: hidden;
                    white-space: nowrap;
                    text-overflow: ellipsis;
                }
            }
        }
        &.empty-list {
            white-space: nowrap;
            .shimmer-box {
                width: 340px;
                height: 233px;
                margin: 0 5px;
                display: inline-block;
                border-radius: 8px;
            }
        }
        &.scroll-container {
            display: flex;
            gap: 8px;
            overflow: auto;

            .journey-item {
                width: 340px;
                flex: 0 0 340px;
                display: block;
            }
        }
    }
}
.page-clear.home-page {
    padding-top: 475px;
}
.page-box {
    &.bg-blue {
        background-color: #0091ff;
    }
}
.catgories-tab {
    background-color: #fafafa;
    position: -webkit-sticky;
    position: sticky;
    top: $default-header-height;
    &.content-box {
        padding: 8px 20px 0 20px;
    }
    @media (max-width: 700px) {
        &.content-box {
            padding: 8px 10px 0 10px;
            top: $mobile-default-header-height;
        }
    }
    z-index: 1;
    .categories-list {
        display: block;
        overflow-x: auto;
        white-space: nowrap;
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */

        .category-tab {
            display: inline-block;
            padding: 4px 15px;
            color: #9e9e9e;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -o-user-select: none;
            user-select: none;
            cursor: pointer;
            &.active {
                background-color: #ffffff;
                border-top-left-radius: 5px;
                border-top-right-radius: 5px;
                color: #000;
            }
        }
        &::-webkit-scrollbar {
            display: none;
        }
    }
}
.place-list {
    .place-item {
        display: inline-block;
        width: calc(50% - 4px);
        margin-bottom: 8px;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -o-user-select: none;
        user-select: none;
        border-radius: 10px;
        background-color: #f0f0f0;
        overflow: hidden;
        &:nth-child(2n) {
            margin-right: 4px;
        }
        &:nth-child(2n + 1) {
            margin-left: 4px;
        }
        @media (min-width: 600px) {
            & {
                width: calc(33.33% - 5.33px);
            }
            &:nth-child(3n) {
                margin-left: 4px;
                margin-right: 4px;
            }
            &:nth-child(3n + 1) {
                margin-left: 4px;
                margin-right: 0;
            }
            &:nth-child(3n + 2) {
                margin-right: 4px;
                margin-left: 0;
            }
        }
        .place-img {
            padding-top: 75%;
            position: relative;
            img {
                position: absolute;
                left: 0;
                right: 0;
                top: 0;
                bottom: 0;
                object-fit: cover;
                width: 100%;
                height: 100%;
            }
        }
        .place-info {
            padding: 8px;
            .place-info-header {
                display: flex;
                align-items: center;
                margin-bottom: 7px;
                .place-name {
                    color: #6d24ea;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    font-size: 13px;
                    margin: 0;
                    flex: 1;
                    &.color-black {
                        color: #000;
                    }
                }
                .shop-open {
                    color: #0091ff;
                    font-size: 10px;
                    border: 1px solid #0091ff;
                    padding: 2px 10px;
                    line-height: 1.7;
                    border-radius: 7px;
                    text-decoration: none;
                    margin: 0;
                }
                .shop-close {
                    color: #e02020;
                    font-size: 10px;
                    border: 1px solid #e02020;
                    padding: 2px 10px;
                    line-height: 1.7;
                    border-radius: 7px;
                    text-decoration: none;
                    margin: 0;
                }
            }
            .place-description {
                font-size: 11px;
                max-lines: 2;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.7;
                margin-bottom: 5px;
                height: 38px;
            }
            .place-footer {
                display: flex;
                align-items: center;
                .place-rate {
                    flex: 1;
                    font-size: 10px;
                    i {
                        margin-right: 2px;
                        &.icomoon-start-fill {
                            color: #fdc60a;
                        }
                        &.icomoon-start-empy {
                            color: #d1d1d1;
                        }
                    }
                    span {
                        font-size: 11px;
                        color: #8a8a8f;
                    }
                }
                .place-reviews {
                    font-size: 11px;
                    color: #e17053;
                }
            }
        }
    }
}
.tag-info {
    max-width: $page-max-width;
    margin: auto;
    padding: 0 20px;
    @media (max-width: 700px) {
        padding: 0 0;
    }
    .tag-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        max-height: 50vh;
    }
    .tag-name {
        max-width: 500px;
        width: 100%;
        margin: auto;
        margin-top: -20px;
        border: 1px solid #ffc524;
        text-align: center;
        color: #000000;
        background-color: #ffffff;
        position: relative;
        font-weight: 600;
        padding: 8px 10px;
        border-radius: 50px;
    }
    .tag-description {
        padding: 8px 10px;
        text-align: center;
        font-size: 12px;
    }
}

.recommend-blogs-list {
    .blog-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        gap: 8px;
        .blog-img {
            width: 30%;
            border-radius: 8px;
            position: relative;
            .blog-marker {
                font-weight: 400;
                position: absolute;
                border-top-left-radius: 8px;
                padding: 2px 8px;
                background-color: #8452c6;
                color: #ffffff;
                font-size: 13px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
                aspect-ratio: 4 / 3;
                @supports not (aspect-ratio: 4 / 3) {
                    &::before {
                        float: left;
                        padding-top: 75%;
                        content: "";
                    }

                    &::after {
                        display: block;
                        content: "";
                        clear: both;
                    }
                }
            }
            .place-name {
                display: block;
                background: #000000b3;
                position: absolute;
                bottom: 0;
                margin: 0;
                padding: 8px 10px;
                color: #ffffff;
                width: 100%;
                font-size: 12px;
                border-bottom-left-radius: 8px;
                border-bottom-right-radius: 8px;
                line-height: 1.7;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                i {
                    font-weight: bold;
                    font-size: 8px;
                    margin-right: 5px;
                }
            }
        }
        .blog-detail {
            flex: 1;
            padding: 5px;
            p {
                margin: 0;
            }
            .blog-title {
                font-size: 15px;
                font-weight: bold;
                margin-bottom: 5px;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2; /* number of lines to show */
                line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                line-height: 1.7;
            }
            .blog-place {
                font-size: 12px;
                margin-bottom: 5px;
                i {
                    font-weight: bold;
                    font-size: 8px;
                    margin-right: 5px;
                }
            }
            .blog-author {
                display: flex;
                align-items: center;
                gap: 8px;
                .author-icon {
                    width: 35px;
                    height: 35px;
                    img {
                        width: 100%;
                    }
                }
                .author-info {
                    padding: 5px;
                    .author-by {
                        font-size: 10px;
                        color: #86888a;
                    }
                    .author-name {
                        font-size: 12px;
                        color: #0d47a1;
                    }
                }
            }
        }
        &:nth-child(2),
        &:nth-child(3) {
            display: inline-block;
            width: calc(50% - 4px);
            vertical-align: top;
            .blog-img {
                width: 100%;
            }
            .blog-detail {
                padding: 5px 0;
            }
        }
        &:nth-child(2) {
            margin-right: 4px;
        }
        &:nth-child(3) {
            margin-left: 4px;
        }
    }
}

.blog-detail-page {
    .blog-information {
        padding-bottom: 15px;
        max-width: $page-max-width;
        margin: auto;
        img {
            max-width: 100%;
            margin-bottom: 8px;
        }
        p {
            margin-bottom: 5px;
            padding: 0 10px;
            line-height: 1.7;
            font-size: 13px;
        }
        figure {
            margin: 0;
        }
    }
}

.place-content {
    .place-img {
        display: flex;
        max-width: $page-max-width;
        margin: auto;
        padding: 0;

        .main-img {
            flex: 1;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        .sub-img {
            .sub-place-img {
                width: 25vw;
                &.column_3 {
                    width: 33.33vw;
                }
                &.column_2 {
                    width: 50vw;
                }
                position: relative;
                @media (min-width: $page-max-width) {
                    max-width: calc(($page-max-width) / 4);
                }
                img {
                    width: 100%;
                    aspect-ratio: 4 / 3;
                    @supports not (aspect-ratio: 4 / 3) {
                        &::before {
                            float: left;
                            padding-top: 75%;
                            content: "";
                        }

                        &::after {
                            display: block;
                            content: "";
                            clear: both;
                        }
                    }
                }
                a.img-more {
                    position: absolute;
                    left: 0;
                    right: 0;
                    top: 0;
                    bottom: 0;
                    display: flex;
                    background-color: #000000ab;
                    text-decoration: none;
                    align-items: center;
                    justify-content: center;
                    i {
                        font-size: 60px;
                        color: #ffffff;
                    }
                }
            }
        }
    }
    .place-header {
        .place-name {
            font-size: 18px;
            line-height: 1.7;
            font-weight: 600;
            margin-bottom: 8px;
        }
        .place-state {
            display: flex;
            align-items: center;
            gap: 7px;
            .header-rate {
                padding: 5px 10px;
                font-weight: 600;
                background-color: #ffcc00;
                border-radius: 5px;
            }
            .header-evaluation {
                font-size: 14px;
                font-weight: bold;
                color: #000;
                flex: 1;
            }
            .header-count {
                p {
                    margin: 0;
                    font-size: 11px;
                }
            }
        }
    }
    hr {
        max-width: $page-max-width;
        margin: 5px auto;
    }
    .place-actions {
        display: flex;
        align-items: center;
        gap: 7px;
        .place-action-item {
            flex: 1;
            text-align: center;
            i {
                font-size: 30px;
                margin-bottom: 8px;
                display: block;
            }
            p {
                margin: 0;
                font-size: 14px;
            }
        }
    }
    .place-tab-bar {
        max-width: $page-max-width;
        margin: auto;
        display: flex;
        align-items: center;
        background-color: #fafafa;
        position: sticky;
        top: $mobile-default-header-height;
        @media (min-width: 700px) {
            top: $default-header-height;
        }
        .place-tab-item {
            flex: 1;
            text-align: center;
            border-bottom: 3px solid #0000001f;
            padding: 8px;
            font-weight: 400;
            color: #979797;
            font-size: 14px;
            padding: 8px;
            &.active {
                border-color: #448aff;
                color: #448aff;
                font-weight: 600;
            }
        }
    }
    .place-information {
        .place-desc {
            font-size: 12px;
            margin-bottom: 8px;
        }
        .place-tags {
            display: flex;
            flex-wrap: wrap;
            gap: 8px;
            margin-bottom: 8px;
            .place-tag-label {
                border: 1px solid #e5634d;
                border-radius: 20px;
                color: #e5634d;
                padding: 2px 10px;
                font-size: 12px;
                line-height: 1.7;
                white-space: nowrap;
            }
        }
        .place-info-box {
            display: flex;
            align-items: flex-start;
            line-height: 1.7;
            border-top: 1px solid #fafafa;
            padding: 5px 0;

            .info-icon {
                padding: 0 5px;
                i {
                    font-size: 18px;
                }
            }
            .info-text {
                flex: 1;
                padding: 0 8px;
                font-size: 14px;
                overflow: hidden;
                button {
                    border: 0;
                    background: none;
                    padding: 0;
                    text-decoration: underline;
                    color: #0d6efd;
                }
                a {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: block;
                }
                p {
                    margin: 0;
                }
                .open-hours {
                    padding: 5px;
                    color: #9e9e9e;
                }
            }
            .info-right-icon {
                padding: 0 5px;
                i {
                    font-size: 16px;
                }
                img {
                    width: 40px;
                    border: 1px solid #9e9e9e;
                    border-radius: 10px;
                }
            }
        }
        .place-note {
            margin-bottom: 8px;
            font-size: 13px;
            .place-note-title {
                font-size: 15px;
                font-weight: 600;
                padding: 8px 0;
                img {
                    display: inline-block;
                    margin-right: 5px;
                    height: 20px;
                }
            }
            p {
                margin-bottom: 5px;
            }
            img {
                max-width: 100%;
            }
            figure {
                margin-bottom: 5px;
            }
        }
        .how-to-there {
            margin-bottom: 8px;
            .how-title {
                font-size: 16px;
                padding: 5px 0;
                font-weight: 600;
            }
            .method-box {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 5px;
                .method-icon {
                    background-color: #ffcc00;
                    border-radius: 10px;
                    padding: 8px;
                    line-height: 1;
                    i {
                        font-size: 40px;
                    }
                }
                .method-info {
                    flex: 1;
                    p {
                        margin: 0;
                    }
                    .method-name {
                        font-size: 16px;
                        font-weight: 600;
                    }
                    .method-detail {
                        font-size: 11px;
                        color: #7d7d7d;
                        font-weight: 600;
                    }
                }
            }
        }
    }
    .divider {
        height: 8px;
        background-color: #fafafa;
        max-width: $page-max-width;
        margin: 0 auto;
    }
    .place-blog {
        padding-top: 0;
        padding-bottom: 0;
        .blog-header-title {
            padding: 8px 0;
            font-size: 16px;
            font-weight: 600;
        }
        .blog-item {
            margin-bottom: 8px;
            p {
                margin: 0;
            }
            .blog-content {
                display: flex;
                align-items: center;
                gap: 8px;
                margin-bottom: 5px;
                .blog-text {
                    flex: 1;
                    .blog-title {
                        font-size: 13px;
                        font-weight: 500;
                        line-height: 1.7;
                        max-lines: 2;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        color: #101088;
                    }
                    .blog-description {
                        font-size: 11px;
                        font-weight: 400;
                        max-lines: 2;
                        text-overflow: ellipsis;
                        display: -webkit-box;
                        -webkit-line-clamp: 2; /* number of lines to show */
                        line-clamp: 2;
                        -webkit-box-orient: vertical;
                        overflow: hidden;
                        line-height: 1.7;
                    }
                }
                .blog-img {
                    width: 80px;
                    img {
                        max-width: 100%;
                        border-radius: 8px;
                    }
                }
            }
            .blog-author {
                display: flex;
                align-items: center;
                gap: 8px;
                .author-img {
                    width: 30px;
                    img {
                        max-width: 100%;
                        border-radius: 50%;
                    }
                }
                .author-info {
                    flex: 1;
                    .author-name {
                        font-size: 13px;
                        font-weight: 500;
                    }
                    .author-date {
                        font-size: 10px;
                        font-weight: 400;
                    }
                }
                .views {
                    i {
                        font-size: 17px;
                        line-height: 1.7;
                        display: inline-block;
                        vertical-align: middle;
                    }
                    span {
                        font-size: 18px;
                        line-height: 1;
                    }
                }
            }
        }
    }
    .place-reviews {
        padding-top: 0;
        padding-bottom: 0;
        .review-header {
            padding: 8px 0;
            .review-header-title {
                font-size: 16px;
                font-weight: 600;
                margin-bottom: 5px;
                .color-blue {
                    color: #0091ff;
                }
            }
            .reivew-hader-rate {
                font-size: 12px;
                .rating {
                    font-size: 14px;
                }
                i {
                    margin-left: 5px;
                    color: #fdc60a;
                }
                .review-no {
                    font-size: 14px;
                    margin-left: 8px;
                }
            }
        }
        .review-item {
            margin-bottom: 8px;
            .review-header {
                display: flex;
                align-content: center;
                gap: 8px;
                .review-header-img {
                    width: 40px;
                    img {
                        width: 40px;
                        height: 40px;
                        border-radius: 50%;
                        object-fit: cover;
                    }
                }
                .reivew-header-text {
                    flex: 1;
                    p {
                        margin: 0;
                    }
                    .reviewer {
                        color: #000;
                        font-weight: 500;
                        margin-bottom: 5px;
                        font-size: 13px;
                    }
                    .rating {
                        font-size: 10px;
                        i {
                            margin-right: 5px;
                            color: #fdc60a;
                        }
                        span {
                            color: #777777;
                            font-weight: 500;
                        }
                    }
                }
            }
            .review-body {
                p.message {
                    margin-bottom: 8px;
                    font-size: 13px;
                    padding: 0 5px;
                }
                .review-attach {
                    padding: 5px;
                    display: flex;
                    align-items: center;
                    gap: 8px;
                    .attach-img {
                        border: 1px solid #d1d1d1;
                        border-radius: 5px;
                        img {
                            width: 60px;
                            height: 60px;
                            border-radius: 5px;
                        }
                    }
                }
            }
        }
    }
}
.transport-content {
    background-color: #fbf8f8;
    .header-direction {
        background-color: #448aff;
        max-width: $page-max-width;
        margin: auto;
        display: flex;
        align-items: center;
        .direction-from,
        .direction-to {
            flex: 1;
            padding: 15px 20px;
            .direction-name {
                color: #ffffff;
                font-size: 13px;
                margin-bottom: 5px;
            }
            .btn-region-selector {
                color: #fff;
                width: 100%;
                font-size: 20px;
                padding: 5px 0;
            }
            .region-menu {
                max-height: 400px;
                overflow-y: auto;
            }
        }
        .direction-from {
            .btn-region-selector {
                text-align: left;
            }
        }
        .direction-to {
            .direction-name {
                text-align: right;
            }
            .btn-region-selector {
                text-align: right;
            }
            .region-menu {
                inset: 0 0 auto auto !important;
            }
        }
        .direction-arrow {
            color: #ffffff;
            font-size: 30px;
        }
    }
    .transport-date {
        position: sticky;
        background-color: #ffffff;
        top: $default-header-height;
        padding: 10px 0;
        @media (max-width: 700px) {
            padding: 10px 0;
        }
        @media (max-width: 700px) {
            top: $mobile-default-header-height;
        }
        .dM {
            padding: 0;
        }
        .Kq::after {
            display: none;
        }
        .transport-selected {
            background-color: #448aff;
            & > * {
                background-color: transparent;
            }
        }
    }
    .trips-content-box {
        padding: 8px 8px;
        @media (max-width: 700px) {
            padding: 8px 8px;
        }

        .trips-list {
            display: grid;
            grid-template-columns: 1fr;
            gap: 8px;
            @media (min-width: 700px) {
                grid-template-columns: 1fr 1fr;
            }
            .trip-item {
                border: 1px solid #e1e1e1;
                border-radius: 10px;
                .trip-header {
                    display: flex;
                    align-items: center;
                    background-color: #448aff;
                    padding: 10px;
                    color: #ffffff;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                    p {
                        margin: 0;
                    }
                    .code {
                        font-size: 17px;
                    }
                    .name {
                        font-size: 13px;
                    }
                    .time {
                        font-size: 15px;
                    }
                    .header-departure {
                        flex: 1;
                    }
                    .header-arrival {
                        flex: 1;
                        text-align: right;
                    }
                    .header-connect {
                        text-align: center;
                        .duration {
                            font-size: 10px;
                        }
                    }
                }
            }
            .ticket-info {
                background-color: #ffffff;
                border-bottom-left-radius: 10px;
                border-bottom-right-radius: 10px;
                display: flex;
                align-items: center;
                padding: 10px;
                .operator {
                    flex: 1;
                    img {
                        display: inline-block;
                        width: 40px;
                        height: 40px;
                        object-fit: contain;
                        margin-right: 8px;
                    }
                    .operator-name {
                        font-size: 15px;
                        font-weight: 600;
                        margin-right: 8px;
                    }
                    .operator-code {
                        font-size: 12px;
                        font-weight: 600;
                    }
                }
                .ticket-price {
                    cursor: pointer;
                    background-color: #448aff;
                    padding: 5px 16px;
                    border-radius: 50px;
                    color: #ffffff;
                    font-size: 13px;
                    &.disabled {
                        background-color: #f5f5f5;
                    }
                }
            }
        }
    }
    &.transport-bus {
        .header-direction {
            background-color: #35be82;
        }
        .transport-date {
            .transport-selected {
                background-color: #35be82;
            }
        }
        .trips-content-box {
            .trips-list {
                .trip-item {
                    .trip-header {
                        background-color: #35be82;
                    }
                }
                .ticket-info {
                    .ticket-price {
                        background-color: #35be82;
                    }
                }
            }
        }
    }
    &.transport-train {
        .header-direction {
            background-color: #884ea0;
        }
        .transport-date {
            .transport-selected {
                background-color: #884ea0;
            }
        }
        .trips-content-box {
            .trips-list {
                .trip-item {
                    .trip-header {
                        background-color: #884ea0;
                    }
                }
                .ticket-info {
                    .ticket-price {
                        background-color: #884ea0;
                    }
                }
            }
        }
    }
    &.transport-van {
        .header-direction {
            background-color: #ff9300;
        }
        .transport-date {
            .transport-selected {
                background-color: #ff9300;
            }
        }
        .trips-content-box {
            .trips-list {
                .trip-item {
                    .trip-header {
                        background-color: #ff9300;
                    }
                }
                .ticket-info {
                    .ticket-price {
                        background-color: #ff9300;
                    }
                }
            }
        }
    }
    &.transport-ferry {
        .header-direction {
            background-color: #2f86a6;
        }
        .transport-date {
            .transport-selected {
                background-color: #2f86a6;
            }
        }
        .trips-content-box {
            .trips-list {
                .trip-item {
                    .trip-header {
                        background-color: #2f86a6;
                    }
                }
                .ticket-info {
                    .ticket-price {
                        background-color: #2f86a6;
                    }
                }
            }
        }
    }
}
.province-area-content {
    .zone-header {
        display: block;
        gap: 8px;
        padding: 8px;
        overflow: auto;
        white-space: nowrap;
        .zone-header-item {
            text-align: center;
            width: 90px;
            display: inline-block;
            .zone-header-all {
                width: 60px;
                height: 60px;
                background-color: #adafb2;
                border-radius: 50%;
                margin: auto;
                line-height: 59px;
                font-size: 20px;
                margin-bottom: 5px;
            }
            img {
                width: 60px;
                height: 60px;
                border-radius: 50%;
                margin-bottom: 5px;
                border: 2px solid #adafb2;
            }
            p {
                margin: 0;
                font-size: 11px;
                color: #000000;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            &.active {
                .zone-header-all {
                    background-color: #0091ff;
                    color: #ffffff;
                }
                img {
                    border-color: #0091ff;
                }
                p {
                    color: #0091ff;
                }
            }
        }
    }
    .scroll-container {
        max-width: $page-max-width;
        margin: auto;
    }
    .area-map {
        height: calc(100% - 160px) !important;
    }
    .info-content {
        width: 300px;
        .info-header {
            display: flex;
            align-items: center;
            .info-img {
                flex: 0 0 120px;
                img {
                    width: 100%;
                }
            }
            .info-detail {
                padding: 8px;
                flex: 1;
                .zone-name {
                    font-size: 17px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                .zone-detail {
                    font-size: 12px;
                    margin: 0;
                    max-lines: 3;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 3;
                    line-clamp: 3;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 1.7;
                    margin-bottom: 7px;
                }
                .zone-detail-link {
                    background-color: #8b03ff;
                    border-color: #8b03ff;
                    padding: 5px 15px;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 50px;
                    line-height: 1.7;
                }
            }
        }
    }
    .gm-style .gm-style-iw-c {
        max-width: 330px !important;
    }
}

.coming-soon-page {
    &.content-box {
        text-align: center;
        padding-top: 30px;
        padding-bottom: 30px;
    }
    img {
        max-width: 80%;
        margin-bottom: 15px;
    }
    h3 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
    }
    p {
        font-size: 13px;
        font-weight: 500;
        margin-bottom: 10px;
    }
}

.trip-content {
    .trip-detail-page {
        .trip-title {
            font-size: 17px;
            line-height: 1.7;
            font-weight: bold;
            margin-bottom: 8px;
        }
        .trip-detail {
            font-size: 15px;
            line-height: 1.7;
            font-weight: 500;
            margin-bottom: 8px;
            color: #9e9e9e;
        }
        .course-box {
            margin: 10px 0;
            box-shadow: 0 3px 5px 5px #9e9e9e33;
            border-radius: 10px;
            .course-header {
                img {
                    width: 100%;
                    border-top-left-radius: 10px;
                    border-top-right-radius: 10px;
                }
            }
            .course-list {
                padding: 15px;
                padding-top: 25px;
                .trip-course-item {
                    display: flex;
                    gap: 5px;
                    .trip-course-content {
                        flex: 1;
                        .time {
                            margin-bottom: 8px;
                        }
                        .course-detail {
                            margin: 0;
                            margin-bottom: 10px;
                            font-size: 13px;
                            font-weight: 500;
                            min-height: 20px;
                        }
                        .course-duration {
                            font-size: 14px;
                            font-weight: 600;
                            color: #6d7278;
                            margin: 0;
                        }
                    }
                    .trip-course-img {
                        img {
                            width: 100px;
                            height: 100px;
                            object-fit: cover;
                            border-radius: 10px;
                            border: 1px solid #f2f2f2;
                        }
                    }
                }
                ul,
                li {
                    list-style: none;
                    padding: 0;
                }
                .sessions {
                    position: relative;
                }
                li {
                    padding-bottom: 1.5rem;
                    border-left: 1px solid #abaaed;
                    position: relative;
                    padding-left: 20px;
                    margin-left: 10px;
                    &:last-child {
                        border: 0px;
                        padding-bottom: 0;
                    }
                    &:before {
                        content: "";
                        width: 15px;
                        height: 15px;
                        background: white;
                        border: 1px solid #4e5ed3;
                        box-shadow: 3px 3px 0px #bab5f8;
                        box-shadow: 3px 3px 0px #bab5f8;
                        border-radius: 50%;
                        position: absolute;
                        left: -8px;
                        top: 1px;
                    }
                }
                .time {
                    color: #2a2839;
                    font-family: "Poppins", sans-serif;
                    font-weight: 500;
                    @include mobile-and-up {
                        font-size: 0.9rem;
                    }
                    @include mobile-only {
                        margin-bottom: 0.3rem;
                        font-size: 0.85rem;
                    }
                }
                p {
                    color: #4f4f4f;
                    font-family: sans-serif;
                    line-height: 1.5;
                    margin-top: 0.4rem;
                    @include mobile-only {
                        font-size: 0.9rem;
                    }
                }
            }
        }
    }
    .trip-actions {
        padding: 8px 0;
    }
}
.btn-block {
    width: 100%;
}
.recommend-trip-list {
    .trip-item {
        display: flex;
        align-items: flex-start;
        margin-bottom: 8px;
        gap: 8px;
        .trip-img {
            border-radius: 8px;
            position: relative;
            .trip-marker {
                font-weight: 400;
                position: absolute;
                border-top-left-radius: 8px;
                padding: 2px 8px;
                background-color: #8452c6;
                color: #ffffff;
                font-size: 13px;
            }
            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 8px;
            }
        }

        @media (min-width: 600px) {
            &:nth-child(2n),
            &:nth-child(2n + 1) {
                display: inline-block;
                width: calc(50% - 4px);
                vertical-align: top;
                .trip-img {
                    width: 100%;
                }
            }
            &:nth-child(2n) {
                margin-right: 4px;
            }
            &:nth-child(2n + 1) {
                margin-left: 4px;
            }
        }
    }
}
.trip-map-content {
    .trip-map .marker-label {
        width: 120px;
        text-overflow: ellipsis;
        overflow: hidden;
    }
    .info-content {
        width: 350px;
        .info-header {
            display: flex;
            align-items: center;
            .info-img {
                flex: 0 0 120px;
                img {
                    width: 100%;
                }
            }
            .info-detail {
                padding: 8px;
                flex: 1;
                .place-name {
                    font-size: 15px;
                    font-weight: 600;
                    margin-bottom: 5px;
                }
                .place-tag {
                    font-size: 11px;
                    margin: 0;
                    max-lines: 2;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    line-height: 1.7;
                    margin-bottom: 5px;
                    .tag-label {
                        display: inline-block;
                        border: 1px solid #f44236;
                        border-radius: 50px;
                        padding: 2px 10px;
                        font-size: 10px;
                        line-height: 1.4;
                        margin-right: 5px;
                        margin-bottom: 5px;
                        color: #f44236;
                    }
                }
                .zone-detail-link {
                    background-color: #8b03ff;
                    border-color: #8b03ff;
                    padding: 5px 15px;
                    color: #fff;
                    text-decoration: none;
                    border-radius: 50px;
                    line-height: 1.7;
                }
            }
        }
    }
    .gm-style .gm-style-iw-c {
        max-width: 380px !important;
    }
}

.metro-content {
    .metro-map-box {
        max-width: 800px;
        width: 100%;
        margin: auto;
        overflow: auto;
        height: calc(100vh - 210px);
        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        cursor: grab;

        &::-webkit-scrollbar {
            display: none;
        }
        .metro-map-content {
            width: 800px;
            height: 1412px;
            position: relative;
            #img-mapper {
                .img-mapper-img {
                    -webkit-user-drag: none;
                    -khtml-user-drag: none;
                    -moz-user-drag: none;
                    -o-user-drag: none;
                    user-drag: none;
                }
            }
            .point {
                position: absolute;
                z-index: 22;
                padding: 8px;
                border: 1px solid #d1d1d1;
                background-color: #ffffff;
                border-radius: 5px;
                transform: translate(-50%, -105%);
                display: flex;
                gap: 10px;
                &:before {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border: 6px solid transparent;
                    border-top-color: #d1d1d1;
                    border-bottom: 0;
                    margin-left: -6px;
                    margin-bottom: -6px;
                }
                &:after {
                    content: "";
                    position: absolute;
                    bottom: 0;
                    left: 50%;
                    width: 0;
                    height: 0;
                    border: 5px solid transparent;
                    border-top-color: #ffffff;
                    border-bottom: 0;
                    margin-left: -5px;
                    margin-bottom: -5px;
                }
                .select-action-btn {
                    text-align: center;
                    min-width: 25px;
                    i {
                        font-size: 20px;
                    }
                    p {
                        margin: 0;
                        font-size: 9px;
                    }
                    &:hover {
                        color: #0091ff;
                    }
                }
            }
            .marker-point {
                position: absolute;
                z-index: 22;
                transform: translate(-50%, -105%);
                img {
                    width: 50px;
                }
            }
        }
    }
    .metro-header-box {
        background-color: #ffcc00;
        .metro-header {
            width: 100%;
            max-width: 800px;
            margin: auto;
            display: flex;
            align-items: center;
            .switch-btn {
                padding: 8px;
                i {
                    padding: 8px;
                    background-color: #ffffff;
                    border-radius: 50%;
                    font-size: 12px;
                }
            }
            .metro-header-station {
                flex: 1;
                display: flex;
                flex-direction: column;
                gap: 8px;
                padding: 8px;
                .metro-station {
                    input {
                        width: 100%;
                        border-radius: 8px;
                        border: 1px solid #d1d1d1;
                        padding: 5px 10px;
                        line-height: 1;
                    }
                }
            }
        }
    }
    .transform-component-module_content__uCDPE img {
        pointer-events: all;
    }
}
.speech-bubble {
    position: relative;
    background: #00aabb;
    border-radius: 0.4em;
}

.speech-bubble:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 50%;
    width: 0;
    height: 0;
    border: 21px solid transparent;
    border-top-color: #00aabb;
    border-bottom: 0;
    margin-left: -21px;
    margin-bottom: -21px;
}
