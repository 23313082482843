@import "constants";

* {
  box-sizing: border-box;
  font-family: "Helvetica Neue", sans-serif;
}

.footer {
  position: fixed;
  bottom: 0;
  box-shadow: 0 3px 6px #00000029;
  background-color: #ffffff;
  left: 0;
  right: 0;
  border-top: 1px solid #f0f4fa;
  .footer-tabs {
    display: flex;
    align-items: center;
    text-align: center;
    .footer-tab-item {
      flex: 1;
      text-align: center;
      color: #2d3f6e;

      i {
        display: block;
        text-align: center;
        font-size: 25px;
        margin-bottom: 5px;
      }
      .tab-item-title {
        font-size: 10px;
        margin: 0;
      }
      &.active {
        color: #1b2542;
        .tab-item-title {
          font-weight: bold;
        }
      }
    }
  }
}
.ballon {
  background: #ffffff;
  color: #000;
  border-radius: 5px;
  padding: 12px 12.8px;
  max-width: $page-max-width;
  margin: auto;

  position: relative;
  &.ballon-bottom:after {
    border-top: 10px solid #ffffff;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-bottom: 0px solid transparent;
    content: "";
    position: absolute;
    top: 40px;
    left: 160px;
  }
  &.ballon-top {
    &::before {
      border-top: 0px solid transparent;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-bottom: 10px solid #ffffff;
      border-top-left-radius: 5px;
      content: "";
      position: absolute;
      top: -10px;
    }
    &.tab-1::before {
      left: calc(10% - 10px);
    }
    &.tab-2::before {
      left: calc(30% - 10px);
    }
    &.tab-3::before {
      left: calc(50% - 10px);
    }
    &.tab-4::before {
      left: calc(70% - 10px);
    }
    &.tab-5::before {
      left: calc(90% - 10px);
    }
  }
}
.page-content {
  // padding-top: 50px;
  padding-bottom: 66px;
}

.content-box {
  max-width: $page-max-width;
  margin: auto;
  padding: 10px 20px;
  .content-box-header {
    display: flex;
    padding: 8px 0;
    margin: 0;
    align-items: center;
    .page-title {
      font-size: 16px;
      font-weight: bold;
      flex: 1;
      margin: 0;
    }
    .page-link-btn {
      background-color: #2d3f6e;
      padding: 8px 16px;
      color: #fff;
      text-decoration: none;
      border-radius: 50px;
      white-space: nowrap;
    }
  }
  .content-box-sub-header {
    padding: 8px 0;
    margin: 0;
    .page-title {
      font-size: 16px;
      font-weight: bold;
      margin: 0;
      margin-bottom: 5px;
    }
    .page-sub-title {
      color: #505050;
      font-size: 13px;
      font-weight: 400;
      margin: 0;
    }
  }
  .content-box-sub-header-flex {
    padding: 8px 0;
    margin: 0;
    display: flex;
    gap: 8px;
    align-items: center;
    .title-box {
      flex: 1;
      .page-title {
        font-size: 16px;
        font-weight: bold;
        margin: 0;
      }
      .page-sub-title {
        color: #505050;
        font-size: 13px;
        font-weight: 400;
        margin: 0;
      }
    }
  }
  .btn-content-box-selector {
    background-color: #2d3f6e;
    color: #ffffff;
    padding: 5px 18px;
    border-radius: 50px;
  }
  .see_more-btn {
    color: #e5634d;
    font-size: 13px;
    text-decoration: none;
    white-space: nowrap;
    &:hover {
      color: #000;
    }
  }
  @media (max-width: 700px) {
    & {
      padding: 8px 10px;
    }
  }
}
.page-divider {
  height: 16px;
  width: 100%;
  margin: 0;
  border: 0;
  &.grey-bg {
    background-color: #00000073;
  }
}
.banner-box {
  background-color: #f0f4fa;
  .banner-img {
    width: 100%;
    border-radius: 8px;
  }
}

.shimmer {
  background-color: grey;
  -webkit-mask: linear-gradient(-60deg, #333 30%, #3335, #333 70%) right/300% 100%;
  background-repeat: no-repeat;
  animation: shimmer 1.5s infinite;
}
.text-shimmer {
  color: white;

  background: #acacac -webkit-gradient(linear, 100% 0, 0 0, from(#acacac), color-stop(0.5, #ffffff), to(#acacac));

  background-position: -4rem top; /*50px*/
  background-repeat: no-repeat;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  -webkit-animation-name: textShimmer;
  -webkit-animation-duration: 1.5s;
  -webkit-animation-iteration-count: infinite;
  -webkit-background-size: 4rem 100%; /*50px*/
}

@keyframes shimmer {
  100% {
    -webkit-mask-position: left;
  }
}

@-webkit-keyframes textShimmer {
  0% {
    background-position: -4rem top; /*50px*/
  }

  70% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}
@keyframes textShimmer {
  0% {
    background-position: -4rem top; /*50px*/
  }

  70% {
    background-position: 12.5rem top; /*200px*/
  }

  100% {
    background-position: 12.5rem top; /*200px*/
  }
}
.glide .glide__bullets {
  text-align: center;
  padding-top: 8px;
  .slider-dot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-shadow: none;
    background-color: #9f9f9f;
    border: 0;
    margin-right: 5px;
    padding: 0;
    &.glide__bullet--active {
      background-color: #ebb533;
    }
  }
}

.header {
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 11;
  background-color: #ffcc00;
  .header-box {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 35px;
    .header-icon {
      padding: 0 8px;
      a {
        text-decoration: none;
        color: #000;
      }
    }
    .header-title {
      flex: 1;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .header-search {
      flex: 1;
      .search-bar {
        background-color: #fff;
        border-radius: 5px;
        display: flex;
        align-items: center;
        line-height: 1.2;
        padding: 8px 20px;
        .search-icon {
          i {
            line-height: 1.2;
            color: #92c6ff;
          }
          text-decoration: none;
        }
        hr {
          border: 0;
          background-color: #e6e6e6;
          height: 20px;
          width: 1px;
          margin: 0 8px;
        }
        input {
          font-size: 14px;
          width: 100%;
          padding-left: 10px;
          outline: none;
          border: 0;
        }
      }
    }
  }
}
.default-content {
  padding-top: $default-header-height;
  @media (max-width: 700px) {
    & {
      padding-top: $mobile-default-header-height;
    }
  }
}

.spinner {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.spinner div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 8px solid #777;
  border-radius: 50%;
  animation: spinner 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #777 transparent transparent transparent;
}
.spinner div:nth-child(1) {
  animation-delay: -0.45s;
}
.spinner div:nth-child(2) {
  animation-delay: -0.3s;
}
.spinner div:nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

#loader {
  position: absolute;
  left: 50%;
  top: 50%;
  z-index: 1;
  width: 120px;
  height: 120px;
  margin: -76px 0 0 -76px;
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #3498db;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
